import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../animations/fadeInOut';
import { appData } from '../apps/data/apps';
import { App } from '../apps/models/app.model';

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.css'],
  animations: fadeInOut,
})
export class StructureComponent implements OnInit {
  apps: App[];
  constructor() {
    this.apps = appData;
  }

  ngOnInit(): void {}
}
