import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../animations/fadeInOut';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  animations: fadeInOut,
})
export class RequestComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
