import { App } from '../models/app.model';

export const appData: Array<App> = [
  {
    appId: 'randomizer',
    name: 'Randomizer',
    type: 'android',
    description: 'One of the best Randomization applications in the store!',
    detail: `<p>Randomizer is an application that can be used to generate random values in different categories. It includes randomization features like Numbers, Words, Dice, Lots, Colors and more.</p>
    <p>Randomizer is unique because of it's easy to use interface and powerful back-end design. Randomizer contains a lot of different features all of which are based on random values. From a simple number generator to complex lot draws, Randomizer has all the functionalities you need to get yourself ahead of everyone else in randomization. The easiest, most powerful and most accessible randomizer yet.</p>
    <h3>Features:</h3>
    <ul>
    <li> Generate random numbers </li>
    <li> Select a random word from a list of words </li>
    <li> Flip a coin </li>
    <li> Roll dices </li>
    <li> Draw lots </li>
    <li> Spin bottle </li>
    <li> Generate a random color </li>
    </ul>`,
    playUrl:
      'https://play.google.com/store/apps/details?id=com.zerosoftwares.randomizer',
    amazonUrl: 'https://www.amazon.com/dp/B08F1V2SCX/',
    privacyPolicy: `
    <p>Ghostware built the Randomizer app as an Ad Supported app. This SERVICE is provided by Ghostware at no
        cost and is intended for use as is.</p>

    <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
        Personal Information if anyone decided to use our Service.</p>

    <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this
        policy. The Personal Information that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in this Privacy Policy.</p>

    <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
        accessible at Randomizer unless otherwise defined in this Privacy Policy.</p>

    <h1>Information Collection and Use</h1>

    <p>No personally identifiable information is collected by us.</p>

    <h1>Log Data</h1>

    <p>We want to inform you that whenever you use our Service, in a case of an error in the app we may collect data and
        information (through third party products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device name, operating system version, the
        configuration of the app when utilizing our Service, the time and date of your use of the Service, and other
        statistics.</p>

    <h1>Cookies</h1>

    <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
        are sent to your browser from the websites that you visit and are stored on your device’s internal memory.
    </p>

    <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries
        that use “cookies” to collect information and improve their services. You have the option to either accept
        or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our
        cookies, you may not be able to use some portions of this Service.</p>

    <h1>Service Providers</h1>

    <p>We may employ third-party companies and individuals due to the following reasons:</p>
    <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>We want to inform users of this Service that these third parties have access to your Personal Information.
        The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.</p>

    <h1>Links to Other Sites</h1>

    <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to
        that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to
        review the Privacy Policy of these websites. We have no control over and assume no responsibility for the
        content, privacy policies, or practices of any third-party sites or services.</p>

    <h1>Children's Privacy</h1>

    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
        information from children under 13. In the case we discover that a child under 13 has provided us with
        personal information, we immediately delete this from our servers. If you are a parent or guardian and you
        are aware that your child has provided us with personal information, please contact us so that we will be
        able to do necessary actions.</p>

    <h1>Changes to This Privacy Policy</h1>

    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
        for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.</p>`,
  },
  {
    appId: 'todo',
    name: 'Todo',
    type: 'android',
    description:
      'Todo and Notes allows you to create todos with dates and take notes quickly.',
    detail: `<p>Todo and Notes helps you make your day more productive by allowing you to create tasks so that you remember them and also allows you to take notes.</p>
    <h3>Features</h3>
    <ul>
    <li>Create todos as quickly and simply as possible.</li>
    <li>Set priorities and due dates.</li>
    <li>Share your todos.</li>
    <li>Todo Backup and Recovery system.</li>
    <li>Manage completed todos and clear them in one tap.</li>
    </ul>`,
    playUrl: 'https://play.google.com/store/apps/details?id=com.ghostware.todo',
    privacyPolicy: `<p>Ghostware built the Todo app. This SERVICE is provided by Ghostware at no cost and is intended for use as is.</p>

    <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
    
    <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
    
    <p class="h1">Information Collection and Use</p>
    
    <p>No personally identifiable information is knowingly collected from the users.</p>
    
    <p>The app does not use third party services that may collect information used to identify you.</p>
    
    <p class="h1">Log Data</p>
    
    <p>We want to inform you that whenever you use our Service, in a case of an error in the app we may collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
    
    <p class="h1">Cookies</p>
    
    <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device’s internal memory.</p>
    
    <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
    
    <p class="h1">Service Providers</p>
    
    <p>We may employ third-party companies and individuals due to the following reasons:</p>
    <ul>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
    
    <p class="h1">Links to Other Sites</p>
    
    <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
    
    <p class="h1">Children's Privacy</p>
    
    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
    
    <p class="h1">Changes to This Privacy Policy</p>
    
    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>`,
  },
  {
    appId: 'strong-password-generator',
    name: 'Strong Password Generator',
    type: 'browserExtension',
    description:
      'Extension for chromium browsers that helps generate strong passwords!',
    detail: `<p>Strong Password Generator is a tool that can generate strong passwords of up to 50 characters in length.</p>
    <p>Since it has become a problem nowadays to protect your passwords as the process to crack a password is getting faster every day, Strong Password Generator will help you generate passwords that contain alpha-numeric characters and symbols of up to 50 characters in length making your password almost impossible to crack.</p>
    <h3>Features</h3>
    <ul>
    <li>Generate strong passwords of up to 50 characters in length</li>
    <li>Powerful range of filters to customize your password</li>
    <li>Elegant, Dark UI</li>
    </ul>`,
    edgeUrl:
      'https://microsoftedge.microsoft.com/addons/detail/gicgdlfjaodmhpajgipdoaojllaibdhd',
    chromeUrl:
      'https://chrome.google.com/webstore/detail/strong-password-generator/iofaigegodfaocfeanhadiejpbmkkfof',
    privacyPolicy: ` <p>Ghostware built the Strong Password Generator app as an extension for Microsoft Edge. This SERVICE is provided by Ghostware at no
    cost and is intended for use as is.</p>

<p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
    Personal Information if anyone decided to use our Service.</p>

<p class="h1">Information Collection and Use</p>

<p>We do not collect any personally identifiable information from the users of this service nor does the app use third party services that may collect information used to identify you.</p>

<p class="h1">Children's Privacy</p>

<p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
    information from children under 13. The application provides no way of entering any information..</p>

<p class="h1">Changes to This Privacy Policy</p>

<p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
    for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These
    changes are effective immediately after they are posted on this page.</p>`,
  },
];
