<div class="container mx-auto my-4 p-4" [@fadeInOut]>
  <div
    class="
      col-md-6
      bg-dark
      text-white
      p-4
      m-auto
      rounded
      shadow
      d-flex
      flex-column
    "
  >
    <p class="h1 text-center">Request a project</p>
    <p>
      Please send your request to us through email. We will get back to you as
      soon as possible!
    </p>
    <a
      href="mailto:request@ghostware.xyz?subject=[Project Request]&body=I want the following project created..."
      class="btn btn-primary"
    >
      <span class="iconify" data-icon="mdi-email" data-inline="false"> </span>
      Send Mail
    </a>
  </div>
</div>
