import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOut } from 'src/app/animations/fadeInOut';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  animations: fadeInOut,
})
export class NotFoundComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit(): void {}
}
