<section class="bg-dark text-white d-flex" id="section-intro" #topSlide>
  <div class="text-center m-auto p-2">
    <p class="h1 mx-auto" id="top-text">Ghostware</p>
    <p class="mx-auto mb-auto">Creating software that <b>YOU</b> need</p>
  </div>
</section>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top p-0 shadow">
  <a class="navbar-brand p-2" routerLink="/"
    ><img class="nav-logo" width="48" height="48" src="/assets/images/logo.svg"
  /></a>
  <button
    class="navbar-toggler d-lg-none"
    style="margin-right: 16px"
    type="button"
    data-toggle="collapse"
    data-target="#collapsibleNavId"
    aria-controls="collapsibleNavId"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="iconify" data-icon="mdi:menu" data-inline="false"></span>
  </button>
  <div class="collapse navbar-collapse" id="collapsibleNavId">
    <ul class="navbar-nav mr-auto p-2">
      <li class="nav-item active">
        <a
          class="nav-link"
          [ngClass]="route == 'home' ? 'active' : ''"
          routerLink="/"
          >Home</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [ngClass]="route == 'apps' ? 'active' : ''"
          routerLink="/apps/"
          >Applications</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [ngClass]="route == 'request' ? 'active' : ''"
          routerLink="/request/"
          >Request a Project</a
        >
      </li>
    </ul>
  </div>
</nav>
