import { Location } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  @ViewChild('topSlide') topSlide;
  route;

  constructor(location: Location, router: Router) {
    router.events.subscribe(() => {
      if (location.path() !== '') {
        const locs = location.path().split('/');
        this.route = locs[locs.length - 1];
      } else {
        this.route = 'home';
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (window.scrollY >= 454) {
    } else {
      if (this.topSlide) {
        const el = this.topSlide.nativeElement;
        const filter = 'brightness(' + (1 - scrollY / 454) + ')';
        el.style.filter = filter;
      }
    }
  }

  ngOnInit(): void {}
}
