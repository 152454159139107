<div
  class="container my-2 text-white bg-dark p-4 shadow rounded text-center"
  [@fadeInOut]
>
  <h1 class="h1">Uh oh!</h1>
  <span
    class="iconify"
    data-icon="line-md:emoji-frown"
    data-inline="false"
    data-width="114"
    data-height="114"
  ></span>
  <h2 class="h2">Error 404 spotted</h2>
  <p>Page not found!</p>
  <p>Are you lost? Go back to <a routerLink="/">home</a></p>
  <p>
    If the error persists, please
    <a
      href="mailto:hello@ghostware.xyz?subject=Page Not Working, Gives 404&body=The page `{{
        this.router.url
      }}` is not working!"
    >
      contact us!
    </a>
  </p>
</div>
