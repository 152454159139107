<div class="container text-white" [@fadeInOut]>
  <div class="row my-5">
    <h1>Hierarchy of the website</h1>
  </div>
  <div class="container bg-dark shadow rounded p-2 my-3">
    <div class="p-2">
      <a routerLink="/" class="d-block"
        ><span
          class="text-white iconify"
          data-icon="mdi:home-circle"
          data-inline="false"
        ></span>
        Home</a
      >
      <div class="list-hierarchical">
        <a routerLink="/apps" class="d-block"
          ><span
            class="text-white iconify"
            data-icon="mdi:application"
            data-inline="false"
          ></span>
          Apps</a
        >
        <div class="list-hierarchical">
          <div *ngFor="let app of apps">
            <a routerLink="/apps/{{ app.appId }}" class="d-block">{{
              app.name
            }}</a>
            <div class="row list-hierarchical">
              <a routerLink="/apps/{{ app.appId }}/privacy-policy/"
                >Privacy Policy</a
              >
            </div>
          </div>
        </div>
        <a routerLink="/request" class="d-block"
          ><span
            class="text-white iconify"
            data-icon="mdi:file-document"
            data-inline="false"
          ></span>
          Request</a
        >
        <span class="d-block"
          ><span
            class="iconify"
            data-icon="mdi:file-tree"
            data-inline="false"
          ></span>
          Structure
          <span class="badge badge-info"
            ><span
              class="iconify"
              data-icon="mdi:map-marker"
              data-inline="false"
            ></span>
            You are here
          </span></span
        >
      </div>
    </div>
  </div>
</div>
